<template>
  <v-sheet>
    <v-container id="components" fluid class="py-4 py-lg-8">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">What does RestEaze consist of?</div>
        <h2 class="text-h2 text-lg-h1">Components</h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">RestEaze is and en-to-end solution, solving the problem or recording, analysing and remotely monitoring leg movement during with minimal patient interaction. Components include the sensor hardware, a smartphone app for device interaction and a web server for data storage and subject management.
          </div>
        </v-responsive>
      </div>
      <v-row class="mt-4 px-4">
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          class="px-1 px-lg-2"
        >
          <v-card style="height: 100%;">
            <v-img :src="item.image" style="background: #f2f5f8;" max-height="350" contain></v-img>
            <v-responsive class="mx-auto pa-2 pa-md-3">
              <!-- <div class="text-uppercase font-weight-black text-body-2 primary--text mb-1">hi</div>
              <div class="text-h3 mt-5">{{ item.title }}</div>
              <div class="text-body-1 mt-5">{{ item.text }}</div> -->
              <div class="text-uppercase font-weight-black text-body-2 primary--text mb-1">{{ item.catagory }}</div>
              <div class="title font-weight-bold text-h5">{{ item.title }}</div>
              <div class="secondary--text text--darken-1 text-body-1 mt-1 mb-4">
                {{ item.text }}
              </div>
              <!-- <div class="my-4">
                <router-link :to="item.link.to" class="text-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link>
              </div> -->
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        title: 'RestEaze Sleep Band',
        catagory: 'Wearable Sensor',
        image: require('@/assets/images/Wearable-shadow.png'),
        text: 'The RestEaze sleep band is a patented ankle-worn wearable with in-built capacitive textile sensors, 9-axis inertial measurement unit, a photoplethysmography, and a temperature sensors for characterizing leg movements during sleep. Data can be streamed from the device in real-time, or stored locally for later offload.',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }, {
        title: 'RestEaze Mobile',
        catagory: 'Mobile App',
        image: require('@/assets/images/MobileApp.png'),
        text: 'The RestEaze mobile app (currently available for Android, coming soon for iOS) receives and stores raw sensor data, which is saved to local device files or uploaded to the Tanzen Medical server for storage. Visualizations for nightly sleep data are available, and sleep hygiene suggestions are coming soon!',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }, {
        title: 'Tanzen Med Server',
        catagory: 'Cloud',
        image: require('@/assets/images/Backend.png'),
        text: 'Tanzen Med\'s backend server stores, and makes available for download, raw data as well as the output from our analytics, which are run at the end of each night\'s recording. Users can view their Dashboard to see their nightly and aggregated statistics. Administrative users can view phyisician oriented plots and statistiscs for each of their subjects.',
        link: {
          title: 'Learn More',
          to: '#'
        }
      }]
    }
  }
}
</script>
