<template>
  <v-sheet id="features" color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
        >
          <v-card class="py-4 px-2" style="height: 100%;">
            <v-responsive max-width="380" class="mx-auto text-center">
              <div class="mb-2">
                <v-icon class="text-h2" color="primary">{{ item.icon }}</v-icon>
              </div>
              <div class="text-h4 mt-3">{{ item.title }}</div>
              <div class="font-weight-regular mt-2">{{ item.description }}</div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-seat-legroom-extra',
        title: 'Leg Movement Detection',
        description: 'Signal processing and machine learning algorithms work to accurately label Leg Movements during Sleep (LMS), PLMS and Arousals.'
      }, {
        icon: 'mdi-medical-bag',
        title: 'Clinical Sleep Metrics',
        description: 'Time-in-bed and sleep onset/offset and awakenings are determined through body position and movements during sleep.'
      }, {
        icon: 'mdi-weather-night',
        title: 'Multi-Night Recording',
        description: 'Measuring night-to-night variability in sleep behavior is a problem for PSG recordings that are often limited to a single night. We design our products for multi-night recording to ensure screening reliability.'
      }, {
        icon: 'mdi-notebook-edit-outline',
        title: 'Digital Sleep Diary',
        description: 'Our sleep diary system is integrated into its our server and RestEaze mobile application, to include patient subjective measures in research studies and patient management.'
      }, {
        icon: 'mdi-monitor-dashboard',
        title: 'Physician Dashboard',
        description: 'RestEaze web services allow Physicians to create and manage patient accounts, where the processed patient data is presented to Physicians for interpretation.'
      }, {
        icon: 'mdi-account-multiple',
        title: 'Bi-Lateral Recording',
        description: 'Research and clinical accuracy is at the core of our products. We provide solutions that can be used for simple monolateral recording as well as more robust bilateral recording with multi-device synchronization.'
      }]
    }
  }
}
</script>
