<template>
  <v-main color="transparent">
    <Toolbar />
    <div>
      <Hero />
      <Feature />
      <Approach />
      <Metrics />
      <Components />
    </div>
    <Footer />
  </v-main>
</template>

<script>
import Toolbar from '@/components/header/Toolbar.vue'
import Hero from '@/components/product/Hero.vue'
import Feature from '@/components/product/Feature.vue'
import Approach from '@/components/about/Approach.vue'
import Metrics from '@/components/product/Metrics.vue'
import Components from '@/components/product/Components.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  components: {
    Toolbar,
    Hero,
    Feature,
    Approach,
    Metrics,
    Components,
    Footer
  }
}
</script>